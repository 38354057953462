import { isEmpty, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import {
  EXPERIMENT_POST_PAGE_SCROLL_TO_COMMENTS,
  EXPERIMENT_SPLIT_POST_PAGE_POC,
} from '@wix/communities-blog-experiments';
import { resolveId } from '@wix/communities-blog-client-common';

import { isInPostPreview } from '../../../common/services/detect-route';
import { getRoute } from '../../../common/router/router-selectors';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  getIsPostLoaded,
  getIsRecentPostsLoaded,
} from '../../../common/store/is-loaded/is-loaded-selectors';
import { getPostByIdOrSlug } from '../../../common/selectors/post-selectors';
import {
  getBasicParams,
  isSeo,
  isEditor,
  isSSR,
  getCommentId,
} from '../../../common/store/basic-params/basic-params-selectors';
import { simulateComponentError } from '../../../common/services/simulate-error';
import scrollToContent from '../../../common/services/scroll-to-content';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import PostPageLayout from '../../components/post-page-layout';
import Post from '../../../common/components/post';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withAuth from '../../../common/hoc/with-auth';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import withDeviceType from '../../../common/hoc/with-device-type';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withTranslate from '../../../common/hoc/with-translate';
import withExperiment from '../../../common/hoc/with-experiment';
import styles from './post-page.scss';
import ReadingTimeListener from '../../components/reading-time-listener/reading-time-listener';
import { isInWix } from '../../../common/services/is-in-wix';
import OnScreen from '../../../common/components/on-screen';
import RelevantPosts from './relevant-posts';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import { PostPageWixComments } from './psot-page-wix-comments-safe';
import { POST_PAGE_COMMENTS_ON_SCREEN_THRESHOLD } from '../../constants/post-page';
import PrintWrapper from '../../../common/components/print-wrapper';

export class PostPage extends Component {
  state = { isFetchingPost: false };

  componentDidMount() {
    const { basicParams } = this.props;
    simulateComponentError(basicParams, 'post-page.container');
    this.blogPostViewTrackEvent();
  }

  blogPostViewTrackEvent = () => {
    const { post, trackEvent } = this.props;
    trackEvent('BlogPostView', {
      event: 'BlogPostView',
      eventCategory: 'Wix Blog',
      eventAction: 'BlogPostView',
      eventLabel: post.title,
      origin: 'Wix Blog',
    });
  };

  getPostContentContainer = () => {
    const post = document.querySelector('[data-hook="post"]');
    return post && post.getBoundingClientRect();
  };

  componentDidUpdate = ({ postSlug: prevPostSlug }) => {
    // hashLinkHandler(this.props.location);
    const { postSlug } = this.props;

    if (!postSlug || prevPostSlug === postSlug) {
      return;
    }

    scrollToContent(styles.postPage);
    this.blogPostViewTrackEvent();
  };

  reportReadingTime = (props) => {
    this.props.biPostScrolled({
      post_id: this.props.post._id,
      is_demo: this.props.post.isDemo,
      ...props,
    });
  };

  onTabVisibilityChange = (props) => {
    this.props.biActiveTabChanged({
      post_id: this.props.post._id,
      is_demo: this.props.post.isDemo,
      ...props,
    });
  };

  renderComments = () => {
    const {
      post,
      showComments,
      commentId,
      isSeo,
      isSSR,
      isExperimentScrollToCommentsEnabled,
    } = this.props;
    const canSeePost = post.canSeePaidContent !== false;
    const renderComments = !isSeo && canSeePost && showComments && post.id;
    if (!renderComments) {
      return null;
    }
    if (!isSSR && commentId) {
      this.props.initWixCommentsController();
      return (
        <Suspense fallback={<AnimatedLoader isLoading />}>
          <PostPageWixComments post={post} />
        </Suspense>
      );
    }
    return (
      <OnScreen
        threshold={
          isExperimentScrollToCommentsEnabled
            ? POST_PAGE_COMMENTS_ON_SCREEN_THRESHOLD
            : undefined
        }
      >
        {({ isOnScreen, setRef }) => {
          if (!isOnScreen) {
            return <div ref={setRef} />;
          }
          this.props.initWixCommentsController();
          return (
            <Suspense fallback={<AnimatedLoader isLoading />}>
              <PostPageWixComments post={post} />
            </Suspense>
          );
        }}
      </OnScreen>
    );
  };

  render = () => {
    const {
      post,
      contentFontClassName,
      isPostLoaded,
      location,
      isSSR,
      showRecentPosts,
      showRelatedPosts,
      relatedPostsLabelKey,
      readingSessionId,
      isSplitPostPagePOCEnabled,
    } = this.props;

    return (
      <PostPageLayout
        className={classNames(styles.postPage, contentFontClassName)}
        data-hook="post-page"
      >
        {!isSSR && !isInWix() && (
          <ReadingTimeListener
            onTabVisibilityChange={(props) => this.onTabVisibilityChange(props)}
            readingSessionId={readingSessionId}
            onScroll={(props) => this.reportReadingTime(props)}
            getContentContainer={this.getPostContentContainer}
          />
        )}
        <AnimatedLoader isLoading={!isPostLoaded && isEmpty(post)}>
          {this.state.page > 1 ? null : (
            <div className={styles.post}>
              <PrintWrapper>
                <Post
                  key={resolveId(post)}
                  post={post}
                  location={location}
                  isInPostPage
                />
              </PrintWrapper>
              <AppLoaded key={post.slug} />
            </div>
          )}
          {!isSplitPostPagePOCEnabled && (
            <RelevantPosts
              post={post}
              showRecentPosts={showRecentPosts}
              showRelatedPosts={showRelatedPosts}
              relatedPostsLabelKey={relatedPostsLabelKey}
            />
          )}
          {this.renderComments()}
        </AnimatedLoader>
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  t: PropTypes.func,
  params: PropTypes.object,
  post: PropTypes.object.isRequired,
  postSlug: PropTypes.string,

  showComments: PropTypes.bool,

  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isEditor: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPostLoaded: PropTypes.bool.isRequired,
  isRecentPostsLoaded: PropTypes.bool.isRequired,
  isSeo: PropTypes.bool.isRequired,

  setIsLoaded: PropTypes.func.isRequired,

  location: PropTypes.object,

  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  biPostScrolled: PropTypes.func.isRequired,
  biActiveTabChanged: PropTypes.func.isRequired,
  initWixCommentsController: PropTypes.func.isRequired,
  commentId: PropTypes.string,

  isExperimentScrollToCommentsEnabled: PropTypes.bool.isRequired,
  isSplitPostPagePOCEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const postSlug = resolvePostSlug(ownProps.params);
  const useDraft = isInPostPreview(getRoute(state));
  let post = getPostByIdOrSlug(state, postSlug) || {};
  post = useDraft ? { ...post, ...(post.draft || {}) } : post;
  const postId = resolveId(post);
  return {
    post,
    postSlug,

    isEditor: isEditor(state),
    isPostLoaded: getIsPostLoaded(state, postSlug),
    isRecentPostsLoaded: getIsRecentPostsLoaded(state, postId),

    isSeo: isSeo(state),
    isSSR: isSSR(state),
    commentId: getCommentId(state),
    readingSessionId: getReadingSessionId(state),

    setIsLoaded: actions.setIsLoaded,
    basicParams: getBasicParams(state),
    trackEvent: actions.trackEvent,
    biPostScrolled: actions.biPostScrolled,
    biActiveTabChanged: actions.biActiveTabChanged,
    initWixCommentsController: actions.initWixCommentsController,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFeedMetadataSettings,
  withDeviceType,
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
  withExperiment({
    isExperimentScrollToCommentsEnabled:
      EXPERIMENT_POST_PAGE_SCROLL_TO_COMMENTS,
    isSplitPostPagePOCEnabled: EXPERIMENT_SPLIT_POST_PAGE_POC,
  }),
)(PostPage);
